import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'

interface Props {
  children: JSX.Element
}
interface State {
}

class Layout extends React.Component<Props , State> {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
            >
              <html lang="ja" />
            </Helmet>
            <div>
              {this.props.children}
            </div>
          </>
        )}
      />
    )
  }
}

export default Layout
