import React from 'react'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h1>21 Lab</h1>
    </div>
  </Layout>
)

export default IndexPage
